import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import LocalizedLink from './LocalizedLink'

const SectionGroup = styled.div`
  /* background: url(${props => props.image});
  background-size: cover; */
  background-color: white;
  display: grid;
  grid-gap: 20px;
  position:relative;
  padding-top:50px;
  padding-bottom:50px;
  color:#293A7E;

`

const SectionLogo = styled.img`
  margin: 0 auto;
  width: 128px;
`

const SectionButton = styled.div`
  margin: 20px auto;
  a {
    color: white;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 9px 20px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    cursor: pointer;
    background-color: #293a7e;
  }

  a:hover {
    color: white;
  }
`
const SectionTtitleGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  margin: 0 40px;
  grid-gap: 20px;
  background-color: (${props => props.image});

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`
const SectionTitle = styled.h3`
  margin: 0;
  font-size: 60px;
  line-height: 1.2;
  color: #293a7e;

  @media (max-width: 720px) {
    font-size: 40px;
  }
`
const SectionText1 = styled.p`
  color: #293a7e;
  max-width: 800px;
  margin: auto;
  text-align: center;
`
const SectionText2 = styled.p`
  color: #293a7e;
  max-width: 800px;
  margin: auto;
  text-align: center;
`
const SectionText3 = styled.p`
  color: #293a7e;
  max-width: 800px;
  margin: auto;
  text-align: center;
  
`
const SectionText4 = styled.p`
  color: #293a7e;
  max-width: 800px;
  margin: auto;
  text-align: center;
`

const Sectionwhite = props => (
  <SectionGroup image={props.image}>
    <SectionLogo src={props.logo} />
    <SectionTtitleGroup>
      <SectionTitle> {props.title} </SectionTitle>
      <SectionText1>{props.text1}</SectionText1>
      <SectionText2>{props.text2}</SectionText2>
      <SectionText3>{props.text3}</SectionText3>
      <SectionText4>{props.text4}</SectionText4>


    </SectionTtitleGroup>
  </SectionGroup>
)

export default Sectionwhite
