import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import Card from '../components/card'
import SelectionNext from '../components/section.3.js'

import Layout from '../components/Layout'
import LocalizedLink from '../components/LocalizedLink'
import Sectionwhite from '../components/sectionwhitenobutton'


const ServicesPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <div className="Hero">
      <div className="HeroGroup">
        <h1>
        {<FormattedMessage id="covidtitle" />}
        </h1>
      </div>
 
</div>
 
    <Sectionwhite
      logo={require('../Images/care.svg')}
      title={<FormattedMessage id="covidherotitle" />}
      text1={<FormattedMessage id="covid1" />}
      text2={<FormattedMessage id="covid2" />}
      text3={<FormattedMessage id="covid3" />}
      text4={<FormattedMessage id="covid4" />}


    />

    <SelectionNext
      titlefirstoption={<FormattedMessage id="OurRooms" />}
      textfirstoption={<FormattedMessage id="OurRoomsIntro" />}
      buttonfirstoption={<FormattedMessage id="Checktherooms" />}
      link1="/rooms"
      titlesecondoption={<FormattedMessage id="Location" />}
      textsecondoption={<FormattedMessage id="OurLocationIntro" />}
      buttonsecondoption={<FormattedMessage id="Checkthelocation" />}
      link2="/location"
    />


  </Layout>
)

ServicesPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default ServicesPage
